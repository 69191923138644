import './App.css';

function App() {
  return (
    <div>
      <main className="app-container">
        <div className="image-container" />
        <section className="firm-container">
          <h2>RAMANS Advocates</h2>
          <p className="message">
            At RAMANS, we stand as the leading legal advocates in India,
            offering exceptional services that come at a premium. We understand
            that excellence comes at a cost, but rest assured, our services are
            an investment that delivers unparalleled results.
          </p>
          <p className="message">
            With an unwavering commitment to integrity, unmatched expertise, and
            personalized attention, we ensure that your legal needs are not only
            met but exceed your expectations.
          </p>
          <div class="services">
            <h3>Services Offered</h3>
            <div className="service-list-container">
              <ul class="service-list">
                <li>Criminal</li>
                <li>Civil</li>
                <li>Family Law</li>
              </ul>
              <ul class="service-list">
                <li>Personal Law</li>
                <li>Company Matters</li>
                <li>Arbitrations</li>
              </ul>
              <ul class="service-list">
                <li>Labour Law</li>
                <li title="For the economically backward">Probono Services*</li>
                <li>Et al.</li>
              </ul>
            </div>
          </div>
          <div class="firm-details"></div>
          <div class="contact-buttons">
            <a href="tel:+919848169695" class="button">
              <span>Call us</span>
            </a>
            <a
              href="mailto:gopal@ramansadvocates.org,aarath@ramansadvocates.org,lakshman@ramansadvocates.org"
              class="button"
            >
              <span>Email us</span>
            </a>
          </div>
        </section>
      </main>
      <footer className="footer">
        <div
          style={{
            backgroundColor: '#000',
            minWidth: '100%',
            minHeight: '100%',
            opacity: 0.7,
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        />
        <div className="footer-content">
          <div className="footer-section">
            <div id="legal-info">
              <h4>Legal Information</h4>
              <div className="legal-info__parts">
                <div id="privacy-policy">
                  <h5>Privacy Policy</h5>
                  <p>
                    RAMANS Advocates collects, uses, and safeguards your
                    publicly available information when you visit our website or
                    engage with our services. We are fully committed to ensuring
                    your privacy and maintaining the security of your data.
                  </p>
                </div>
                <div id="terms-of-service">
                  <h5>Terms of Service</h5>
                  <p>
                    Upon reaching out to RAMANS Advocates and choosing to retain
                    our legal services, we will diligently assess the
                    intricacies of your case. Subsequently, we shall enter into
                    comprehensive discussions regarding the legal agreements,
                    including fee structures and contractual arrangements. It is
                    within this framework that we will proceed to establish a
                    robust and confidential attorney-client relationship,
                    ensuring your legal needs are expertly represented.
                  </p>
                </div>
                <div id="disclaimer">
                  <h5>Disclaimer</h5>
                  <p>
                    This Disclaimer states that the information provided on this
                    website is for general informational purposes only and is
                    not intended as legal advice. RAMANS Advocates does not
                    establish an attorney-client relationship with you through
                    the use of this website or any communication.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-address">
            <h4>Contact Information</h4>
            <p className="address">
              <span style={{ fontWeight: 700 }}>Address:</span> <br />
              RAMANS Advocates <br /> 46-16-24/C, Danavaipeta, Rajahmundry -
              533103, Andhra Pradesh, India
              <br />
              Phone:{' '}
              <a style={{ textDecoration: 'none' }} href="tel:+919848169695">
                +91 98481 69695
              </a>
              <br />
              Email:{' '}
              <a href="mailto:gopal@ramansadvocates.org,aarath@ramansadvocates.org,lakshman@ramansadvocates.org">
                gopal@ramansadvocates.org, aarath@ramansadvocates.org,
                lakshman@ramansadvocates.org
              </a>
            </p>
          </div>
        </div>
        <div className="footer-bottom">
          <p>
            &copy; {new Date().getFullYear()} RAMANS Advocates. All rights
            reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
